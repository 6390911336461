import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import {
  isAppointmentService,
  isServiceWithDynamicDuration,
} from '@wix/bookings-calendar-catalog-viewer-mapper';
import Experiments from '@wix/wix-experiments';

export interface BookButtonViewModel {
  isPendingApprovalFlow?: boolean;
  navigationInitiatedBy?: string;
  shouldOpenServicesPreferencesModal?: boolean;
}
export const bookButtonViewModelFactory = (
  service: Service,
  isMultiServiceAppointmentEnabled: boolean,
  experiments: Experiments,
): BookButtonViewModel => {
  const isMultiServiceAppointmentFlow =
    isMultiServiceAppointmentEnabled && isAppointmentService(service);

  return {
    isPendingApprovalFlow: service?.onlineBooking?.requireManualApproval!,
    shouldOpenServicesPreferencesModal:
      experiments?.enabled('specs.bookings.dynamicDurationUoU') &&
      (isMultiServiceAppointmentFlow || isServiceWithDynamicDuration(service)),
  };
};
