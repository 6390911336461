import { Service } from '@wix/ambassador-bookings-services-v2-service/types';

export interface MediaItemDto {
  height?: number;
  width?: number;
  relativeUri?: string;
  altText?: string;
  fileName?: string;
}
export interface GallerySectionViewModel {
  items: MediaItemDto[];
}

export const gallerySectionViewModelFactory = (
  service: Service,
): GallerySectionViewModel => {
  const items =
    service?.media?.items?.map((item) => {
      return {
        height: item.image?.height,
        width: item.image?.width,
        relativeUri: item.image?.url,
        altText: item.image?.altText || '',
      };
    }) || [];
  return {
    items,
  };
};
