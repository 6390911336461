import { BodyViewModelBuilder } from './builders/BodyViewModel.builder';
import {
  TitleAndTaglineViewModel,
  titleAndTaglineViewModelFactory,
} from './title-and-tagline-view-model/titleAndTaglineViewModel';
import {
  ContactSectionViewModel,
  contactSectionViewModelFactory,
} from './contact-section-view-model/contactSectionViewModel';
import {
  CourseAvailabilityDisplay,
  DetailsSectionViewModel,
  detailsSectionViewModelFactory,
} from './details-section-view-model/detailsSectionViewModel';
import {
  HeaderViewModel,
  headerViewModelFactory,
} from './header-view-model/headerViewModel';
import {
  DescriptionSectionViewModel,
  descriptionSectionViewModelFactory,
} from './description-section-view-modal/descriptionSectionViewModel';
import { HeaderViewModelBuilder } from './builders/HeaderViewModel.builder';
import { TitleAndTaglineViewModelBuilder } from './builders/TitleAndTaglineViewModel.builder';
import { DetailsSectionViewModelBuilder } from './builders/DetailsSectionViewModel.builder';
import { ContactSectionViewModelBuilder } from './builders/ContactSectionViewModel.builder';
import { DescriptionSectionViewModelBuilder } from './builders/DescriptionSectionViewModel.builder';
import {
  PolicySectionViewModel,
  policySectionViewModelFactory,
} from './policy-section-view-model/policySectionViewModel';
import { PolicySectionViewModelBuilder } from './builders/PolicySectionViewModel.builder';
import {
  BodyViewModel,
  bodyViewModelFactory,
} from './body-view-model/bodyViewModel';
import {
  BookButtonViewModel,
  bookButtonViewModelFactory,
} from './book-button-view-model/bookButtonViewModel';
import {
  GallerySectionViewModel,
  gallerySectionViewModelFactory,
} from './gallery-section-view-model/gallerySectionViewModel';
import { GallerySectionViewModelBuilder } from './builders/GallerySectionViewModel.builder';
import {
  Service,
  ServiceType,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { BusinessInfo } from '@wix/bookings-uou-types';
import { BookingPolicyDto } from '../types/shared-types';
import { isCourseService } from '../utils/service';
import {
  DialogViewModel,
  dialogViewModelFactory,
} from './dialogViewModel/dialogViewModel';

export interface ServicePageViewModel {
  header: HeaderViewModel;
  body: BodyViewModel;
  titleAndTagline: TitleAndTaglineViewModel;
  detailsSection: DetailsSectionViewModel;
  contactSection: ContactSectionViewModel;
  descriptionSection: DescriptionSectionViewModel;
  policySection: PolicySectionViewModel;
  gallerySection: GallerySectionViewModel;
  showSchedulingSection: boolean;
  button?: BookButtonViewModel;
  navigationInitiatedBy?: string;
  dialogViewModel: DialogViewModel;
}

export const servicePageViewModelFactory = ({
  service,
  businessInfo,
  bookingsPolicy,
  t,
  experiments,
  viewTimezone,
  isSEO,
  settings,
  isMultiServiceAppointmentEnabled,
}: {
  service: Service;
  businessInfo?: BusinessInfo;
  bookingsPolicy: BookingPolicyDto;
  t;
  isSEO?: boolean;
  viewTimezone?: string;
  experiments?;
  settings?: any;
  isMultiServiceAppointmentEnabled: boolean;
}): ServicePageViewModel => {
  const body = bodyViewModelFactory({
    bookingsPolicy,
    service,
  });
  const isBookable = body.isBookable;
  const hideSchedulingSection =
    isCourseService(service) &&
    (!service.schedule?.lastSessionEnd ||
      service.schedule?.lastSessionEnd < new Date());
  const showSchedulingSection =
    service.type !== ServiceType.APPOINTMENT && !hideSchedulingSection;

  return {
    header: headerViewModelFactory(service, isBookable, isSEO),
    body,
    titleAndTagline: titleAndTaglineViewModelFactory(service),
    detailsSection: detailsSectionViewModelFactory({
      service,
      businessInfo,
      t,
      isBookable,
      viewTimezone,
      bookingsPolicy,
      settings,
      experiments,
    }),
    contactSection: contactSectionViewModelFactory({
      businessInfo,
      service,
    }),
    descriptionSection: descriptionSectionViewModelFactory(service, isBookable),
    policySection: policySectionViewModelFactory(bookingsPolicy),
    showSchedulingSection,
    button: bookButtonViewModelFactory(
      service,
      isMultiServiceAppointmentEnabled,
      experiments,
    ),
    gallerySection: gallerySectionViewModelFactory(service),
    dialogViewModel: dialogViewModelFactory(),
  };
};

export const dummyViewModelFactory = ({
  t,
  isEditorX,
  courseAvailability,
  isWixStudio,
}: {
  t: any;
  isEditorX: boolean;
  courseAvailability?: CourseAvailabilityDisplay;
  isWixStudio: boolean;
}): ServicePageViewModel => {
  const title = t('dummy-data.title');
  const tagline = t('dummy-data.tagline');
  const duration = t('dummy-data.duration');
  const price = t('dummy-data.price');
  const location = t('dummy-data.location');
  const description = t('dummy-data.description');
  const policy = t('dummy-data.policy');
  const phone = t('dummy-data.phone');
  const email = t('dummy-data.email');
  const address = t('dummy-data.address');
  const isBookable = true;
  const galleryEditorMap = {
    classic: [
      {
        fileName: '11062b_1e99942894b4427fabbd87b9e85cbf95~mv2.jpg',
        relativeUri: '11062b_1e99942894b4427fabbd87b9e85cbf95~mv2.jpg',
        width: 2606,
        height: 1745,
      },
      {
        fileName: '11062b_6ec3e1291f63411b84ce766a74e99608~mv2.jpg',
        relativeUri: '11062b_6ec3e1291f63411b84ce766a74e99608~mv2.jpg',
        width: 2606,
        height: 1745,
      },
      {
        fileName: '11062b_1c4adda107a544c5b89d0c069238eb9a~mv2.jpg',
        relativeUri: '11062b_1c4adda107a544c5b89d0c069238eb9a~mv2.jpg',
        width: 2606,
        height: 1745,
      },
      {
        fileName: '11062b_60d57732a78546119d2524ec64ad12df~mv2.jpg',
        relativeUri: '11062b_60d57732a78546119d2524ec64ad12df~mv2.jpg',
        width: 3648,
        height: 4209,
      },
    ],
    editorX: [
      {
        fileName: '11062b_1f94cea4fec64357b49a1880bebfd072~mv2.jpg',
        relativeUri: '11062b_1f94cea4fec64357b49a1880bebfd072~mv2.jpg',
        width: 2240,
        height: 1500,
      },
      {
        fileName: '11062b_a8466e6c581e4820b9c32c13e5a7dabb~mv2.jpg',
        relativeUri: '11062b_a8466e6c581e4820b9c32c13e5a7dabb~mv2.jpg',
        width: 2240,
        height: 1500,
      },
      {
        fileName: '11062b_df81c58f2799405ebec3bb5ebb7d6155~mv2.jpg',
        relativeUri: '11062b_df81c58f2799405ebec3bb5ebb7d6155~mv2.jpg',
        width: 2240,
        height: 1500,
      },
      {
        fileName: '11062b_a2e41b4d43ba4c78b7ca7375142a8f38~mv2.jpg',
        relativeUri: '11062b_a2e41b4d43ba4c78b7ca7375142a8f38~mv2.jpg',
        width: 3648,
        height: 4209,
      },
    ],
    studio: [
      {
        fileName: '2f543c_aec7bf888dd74b218db9495c52e2f030~mv2.jpg',
        relativeUri: '2f543c_aec7bf888dd74b218db9495c52e2f030~mv2.jpg',
        width: 2240,
        height: 1500,
      },
      {
        fileName: '2f543c_7ac5dc67e9cd4a52b52e0ee9ac9fe9ef~mv2.jpg',
        relativeUri: '2f543c_7ac5dc67e9cd4a52b52e0ee9ac9fe9ef~mv2.jpg',
        width: 2240,
        height: 1500,
      },
      {
        fileName: '2f543c_4afdfa84c505477b94d34441a73c5e87~mv2.jpg',
        relativeUri: '2f543c_4afdfa84c505477b94d34441a73c5e87~mv2.jpg',
        width: 2240,
        height: 1500,
      },
    ],
  };
  const imageUriEditorMap = {
    classic: '11062b_7344b0fffa9c41e580794c15cea365d5~mv2.jpg',
    editorX: '11062b_7f7efcdb410c48c2a65be450de809052~mv2.jpg',
    studio: '2f543c_9849983df3bf4930a2e72e7accaac00f~mv2.jpg',
  };
  const editorName = isWixStudio ? 'studio' : isEditorX ? 'editorX' : 'classic';

  const detailsSectionViewModelBuilder = new DetailsSectionViewModelBuilder()
    .withDuration(duration)
    .withPrice(price)
    .withLocation(location)
    .withBookOnlineAllowed(isBookable)
    .withFullyBooked(false);

  if (courseAvailability) {
    const numberOfSpotsLeft =
      courseAvailability === CourseAvailabilityDisplay.AVAILABILITY
        ? t('dummy-data.course-available')
        : t('dummy-data.number-of-spots-left');
    detailsSectionViewModelBuilder.withNumberOfSpotsLeft(numberOfSpotsLeft);
  }

  return {
    header: new HeaderViewModelBuilder()
      .withImage({
        relativeUri: imageUriEditorMap[editorName],
        height: 0,
        width: 0,
      })
      .withTitle(title)
      .withBookOnlineAllowed(isBookable)
      .build(),
    body: new BodyViewModelBuilder().withBookOnlineAllowed(isBookable).build(),
    titleAndTagline: new TitleAndTaglineViewModelBuilder()
      .withTitle(title)
      .withTagline(tagline)
      .withAvailableOnline(isBookable)
      .build(),
    detailsSection: detailsSectionViewModelBuilder.build(),
    contactSection: new ContactSectionViewModelBuilder()
      .withPhone(phone)
      .withEmail(email)
      .withAddress(address)
      .build(),
    descriptionSection: new DescriptionSectionViewModelBuilder()
      .withDescription(description)
      .withBookOnlineAllowed(isBookable)
      .build(),
    policySection: new PolicySectionViewModelBuilder()
      .withCancellationPolicy(policy)
      .build(),
    showSchedulingSection: true,
    button: { isPendingApprovalFlow: false },
    gallerySection: new GallerySectionViewModelBuilder()
      .withItems(galleryEditorMap[editorName])
      .build(),
    dialogViewModel: dialogViewModelFactory(),
  };
};
