import { BusinessInfo, TimezoneType } from '@wix/bookings-uou-types';
import { GetBusinessResponse } from '@wix/ambassador-services-catalog-server/types';
import { getUoULocale } from '../domain/locale';

export enum BusinessPropertyName {
  CANCELLATION_POLICY = 'cancellationPolicy',
  DEFAULT_TIMEZONE_PROPERTY_NAME = 'defaultTimezone',
  CLIENT_CAN_CHANGE_TIMEZONE = 'clientCanChangeTimezone',
  MULTI_SERVICES_APPOINTMENTS_PROPERTY_NAME = 'isMultiServicesAppointmentsEnable',
}

export const mapResponseToBusinessInfo = (
  businessResponse?: GetBusinessResponse,
): BusinessInfo | undefined => {
  if (!businessResponse?.info || !businessResponse?.businessProperties) {
    return;
  }
  const businessInfo = businessResponse.info;
  const businessProperties = businessResponse.businessProperties;

  const clientCanChangeTimezone =
    getPropertyValue(
      businessProperties?.customProperties,
      BusinessPropertyName.CLIENT_CAN_CHANGE_TIMEZONE,
    ) || 'true';

  const defaultTimezone =
    getPropertyValue(
      businessProperties?.customProperties,
      BusinessPropertyName.DEFAULT_TIMEZONE_PROPERTY_NAME,
    ) || TimezoneType.BUSINESS;

  return {
    name: businessInfo.name,
    phone: businessInfo.phone,
    email: businessInfo.email,
    language: getUoULocale(businessInfo.language),
    currency: businessInfo.currency,
    address: businessInfo.formattedAddress,
    timeZone: businessInfo.timeZone,
    dateRegionalSettingsLocale: formatDateRegionalSettingsLocale(
      businessInfo.language,
      businessInfo.countryCode,
    ),
    timezoneProperties: {
      clientCanChooseTimezone: clientCanChangeTimezone === 'true',
      defaultTimezone,
    },
  };
};

export function formatDateRegionalSettingsLocale(language, countryCode) {
  const locale = language?.split('-')[0];
  return `${locale}${countryCode ? `-${countryCode}` : ``}`;
}

export function getPropertyValue(properties, propertyName) {
  const property = properties?.find(
    (businessProperty) => businessProperty.propertyName === propertyName,
  );
  return property?.value;
}
