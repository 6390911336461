import {
  GallerySectionViewModel,
  MediaItemDto,
} from '../gallery-section-view-model/gallerySectionViewModel';

export class GallerySectionViewModelBuilder {
  private viewModel: GallerySectionViewModel = {
    items: [],
  };

  withItems(items: MediaItemDto[]) {
    this.viewModel.items = items;
    return this;
  }
  build(): GallerySectionViewModel {
    return this.viewModel;
  }
}
