import { Service } from '@wix/ambassador-bookings-services-v2-service/types';

export interface TitleAndTaglineViewModel {
  title: string;
  tagline?: string;
  availableOnline?: boolean;
}

export const titleAndTaglineViewModelFactory = (
  service: Service,
): TitleAndTaglineViewModel => {
  const viewModel: TitleAndTaglineViewModel = {
    title: service.name!,
    tagline: service.tagLine || undefined,
  };
  viewModel.availableOnline = !!service.conferencing?.enabled;
  return viewModel;
};
