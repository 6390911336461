import {
  SchedulingLocationViewModel,
  schedulingLocationViewModelFactory,
} from '../../../service-page-view-model/scheduling-location-view-model/schedulingLocationViewModel';
import {
  ServicePageViewModel,
  servicePageViewModelFactory,
} from '../../../service-page-view-model/servicePageViewModel';
import {
  SchedulingTimezoneViewModel,
  schedulingTimezoneViewModelFactory,
} from '../../../service-page-view-model/shceduling-timezone-view-model/schedulingTimezoneViewModel';

export const initializeViewModels = ({
  service,
  businessInfo,
  bookingsPolicy,
  selectedLocation,
  isBookingCalendarInstalled,
  settings,
  flowAPI,
  isMultiServiceAppointmentEnabled,
}): {
  viewModel: ServicePageViewModel;
  locationViewModel: SchedulingLocationViewModel;
  timezoneViewModel: SchedulingTimezoneViewModel;
} => {
  const { experiments, environment } = flowAPI;
  const { isSEO } = environment;
  const locationViewModel: SchedulingLocationViewModel =
    schedulingLocationViewModelFactory({
      service,
      selectedLocation,
      t: flowAPI.translations.t,
    });
  const timezoneViewModel: SchedulingTimezoneViewModel =
    schedulingTimezoneViewModelFactory({
      businessInfo,
      isBookingCalendarInstalled,
    });
  const viewModel: ServicePageViewModel = servicePageViewModelFactory({
    service,
    businessInfo,
    bookingsPolicy,
    t: flowAPI.translations.t,
    experiments,
    viewTimezone: timezoneViewModel.viewTimezone,
    isSEO,
    settings,
    isMultiServiceAppointmentEnabled,
  });
  return {
    viewModel,
    locationViewModel,
    timezoneViewModel,
  };
};
