export const shouldRedirectToCalendarPage = (wixCodeApi) => {
  const pageName = wixCodeApi.site.currentPage.url?.substring(1).toLowerCase();
  const pathParams = wixCodeApi.location.path.map((path) => path.toLowerCase());
  const pageNameIndex = pathParams.indexOf(pageName);
  if (
    pageNameIndex !== -1 &&
    pathParams.length > 2 &&
    pathParams[pageNameIndex + 2] === 'book'
  ) {
    return true;
  }
  return false;
};
