export const SUPPORTED_UOU_LOCALES = [
  'ar',
  'bg',
  'cs',
  'da',
  'de',
  'el',
  'en',
  'es',
  'fi',
  'fr',
  'he',
  'hi',
  'hu',
  'id',
  'it',
  'ja',
  'ko',
  'ms',
  'nl',
  'no',
  'pl',
  'pt',
  'ro',
  'ru',
  'sv',
  'th',
  'tl',
  'tr',
  'uk',
  'zh',
  'lt',
  'vi',
  'ca',
  'sk',
  'sl',
  'hr',
  'lv',
];

export const DEFAULT_LOCALE = 'en';

export const getUoULocale = (locale) =>
  SUPPORTED_UOU_LOCALES.indexOf(locale) > -1 ? locale : DEFAULT_LOCALE;
