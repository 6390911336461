import { BookingPolicyDto } from '../../types/shared-types';
import { Service } from '@wix/ambassador-bookings-services-v2-service/types';

export interface BodyViewModel {
  messageType?: AVAILABILITY_MESSAGE_TYPE;
  isBookable: boolean;
  timeUntilServiceIsOpen: string;
  serviceName: string;
}

// https://stackoverflow.com/questions/63961803/eslint-says-all-enums-in-typescript-app-are-already-declared-in-the-upper-scope
// eslint-disable-next-line no-shadow
export enum AVAILABILITY_MESSAGE_TYPE {
  FULLY_BOOKED = 'FULLY_BOOKED',
  TOO_EARLY = 'TOO_EARLY',
  TOO_LATE = 'TOO_LATE',
  STARTED_AND_BOOKABLE = 'STARTED_AND_BOOKABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}

const getServiceAvailability = ({
  isFullyBooked,
  isServiceStartedAndBookable,
  isTooLateToBook,
  isServiceAvailable,
  isTooEarlyToBook,
  isBookable,
}: BookingPolicyDto) => {
  if (isFullyBooked || !isServiceAvailable || isTooEarlyToBook) {
    return false;
  }
  if (isTooLateToBook) {
    return isServiceStartedAndBookable;
  }
  return isBookable;
};

export const bodyViewModelFactory = ({
  bookingsPolicy,
  service,
}: {
  bookingsPolicy: BookingPolicyDto;
  service: Service;
}): BodyViewModel => {
  const messageType = getAvailabilityMessageType(bookingsPolicy);
  const isAvailableService = getServiceAvailability(bookingsPolicy);
  return {
    messageType,
    isBookable: isAvailableService,
    timeUntilServiceIsOpen: bookingsPolicy.timeUntilServiceIsOpen,
    serviceName: service.name!,
  };
};

const getAvailabilityMessageType = (
  bookingPolicyDto: BookingPolicyDto,
): AVAILABILITY_MESSAGE_TYPE | undefined => {
  if (bookingPolicyDto.isBookable) {
    if (bookingPolicyDto.isFullyBooked) {
      return AVAILABILITY_MESSAGE_TYPE.FULLY_BOOKED;
    } else if (bookingPolicyDto.isTooEarlyToBook) {
      return AVAILABILITY_MESSAGE_TYPE.TOO_EARLY;
    } else if (bookingPolicyDto.isTooLateToBook) {
      return AVAILABILITY_MESSAGE_TYPE.TOO_LATE;
    } else if (!bookingPolicyDto.isServiceAvailable) {
      return AVAILABILITY_MESSAGE_TYPE.NOT_AVAILABLE;
    } else if (bookingPolicyDto.isServiceStartedAndBookable) {
      return AVAILABILITY_MESSAGE_TYPE.STARTED_AND_BOOKABLE;
    }
  }
};
