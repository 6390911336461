import { Service } from '@wix/ambassador-bookings-services-v2-service/types';

export interface DescriptionSectionViewModel {
  description: string;
  isBookable: boolean;
}
export const descriptionSectionViewModelFactory = (
  service: Service,
  isBookable: boolean,
): DescriptionSectionViewModel => {
  return {
    description: service.description!,
    isBookable,
  };
};
