import type { ServicesPreferencesModalData } from '@wix/bookings-services-preferences-modal/types';

export interface ServicePreferencesDialogViewModel {
  data: ServicesPreferencesModalData;
  isOpen: boolean;
}

export interface DefaultDialogViewModel {
  isOpen: boolean;
  data?: never;
}

export type DialogViewModel =
  | ServicePreferencesDialogViewModel
  | DefaultDialogViewModel;

export const dialogViewModelFactory = (): DialogViewModel => ({
  isOpen: false,
});
