import { fixFormattedDateToISOString } from '../../../utils/dateFormatter';
import { getSchedule } from '../../../api/schedule.api';
import { SchedulingSectionStatus } from '../../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import { CatalogSessionDto, WidgetConfig } from '../../../types/shared-types';
import { ServiceType } from '@wix/bookings-uou-types';
import { ScheduleStatus } from '@wix/ambassador-services-catalog-server/types';
import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import {
  isAppointmentService,
  isCourseService,
} from '@wix/bookings-calendar-catalog-viewer-mapper';
import { BookingsApi } from '../../../api/BookingsApi';
import type { Event } from '@wix/ambassador-calendar-v3-event/types';

export type ServiceSchedulingData = {
  status?: SchedulingSectionStatus;
  sessions?: Event[] | CatalogSessionDto[];
};

export async function getServiceSchedulingData({
  settings,
  httpClient,
  locationId,
  isIncreaseServicePageTimeBudget,
  useQueryEvents,
  bookingsApi,
  service,
  timezone,
}: {
  settings: any;
  httpClient: any;
  locationId?: string;
  isIncreaseServicePageTimeBudget: boolean;
  service: Service;
  useQueryEvents?: boolean;
  bookingsApi: BookingsApi;
  timezone: string;
}): Promise<ServiceSchedulingData> {
  const isCourse = isCourseService(service);
  const isAppointment = isAppointmentService(service);

  if (!isAppointment) {
    const schedule = service.schedule;
    const now = new Date();
    const from = isCourse
      ? new Date((schedule?.firstSessionStart as Date) || null)
      : new Date(now);
    const to = isCourse
      ? new Date((schedule?.lastSessionEnd as Date) || null)
      : new Date(now.setDate(now.getDate() + settings.scheduleDays));
    if (from && to) {
      return useQueryEvents
        ? bookingsApi
            .queryEvents({
              from,
              to,
              scheduleId: schedule?.id!,
              timezone,
            })
            .then(({ data: { events } }) => ({ sessions: events }))
        : httpClient
            .request({
              ...getSchedule(
                [schedule?.id],
                from.toISOString(),
                to.toISOString(),
                locationId,
              ),
              headers: isIncreaseServicePageTimeBudget
                ? { 'x-time-budget': '10000' }
                : undefined,
            })
            .then(({ data }) => data);
    }
  }
  return { status: SchedulingSectionStatus.EMPTY };
}
