import { Service } from '@wix/ambassador-bookings-services-v2-service/types';

export interface HeaderViewModel {
  title: string;
  image?: {
    relativeUri: string;
    width: number;
    height: number;
    altText?: string;
  };
  isBookable: boolean;
  isSEO?: boolean;
}

export const headerViewModelFactory = (
  service: Service,
  isBookable: boolean,
  isSEO?: boolean,
): HeaderViewModel => {
  const coverMedia = service.media?.coverMedia;
  return {
    title: service.name!,
    image: coverMedia
      ? {
          relativeUri: service.media?.coverMedia?.image?.url || '',
          width: service.media?.coverMedia?.image?.width || 0,
          height: service.media?.coverMedia?.image?.height || 0,
          altText: service.media?.coverMedia?.image?.altText || '',
        }
      : undefined,
    isBookable,
    isSEO,
  };
};
