import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import type { Basket } from '@wix/bookings-services-preferences-modal/types';
import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import { ServicePageViewModel } from '../../../../service-page-view-model/servicePageViewModel';
import { ServicePreferencesDialogViewModel } from '../../../../service-page-view-model/dialogViewModel/dialogViewModel';
import { isServiceVariedPricing } from '@wix/bookings-calendar-catalog-viewer-mapper';
import { BusinessInfo } from '@wix/bookings-uou-types';

const DO_NOT_CARE_STAFF = 'DO_NOT_CARE';

export type OpenServicesPreferencesModalAction = () => Promise<void>;

export const createOpenServicesPreferencesModalAction = ({
  service,
  viewModel,
  flowAPI,
  businessInfo,
  isMultiServiceAppointmentEnabled,
}: {
  service: Service;
  viewModel: ServicePageViewModel;
  flowAPI: ControllerFlowAPI;
  businessInfo: BusinessInfo;
  isMultiServiceAppointmentEnabled: boolean;
}): OpenServicesPreferencesModalAction => {
  const {
    controllerConfig: { setProps },
  } = flowAPI;

  return async () => {
    const { createModalData } = await import(
      '@wix/bookings-services-preferences-modal/utils'
    );
    const { enrichModalData } = await import(
      '@wix/bookings-services-preferences-modal/controllerActions'
    );

    const basket = getBasket(service);

    const modalData = createModalData({
      regionalSettingsLocale: businessInfo.dateRegionalSettingsLocale!,
      service,
      basket,
      ...(isMultiServiceAppointmentEnabled ? {} : { maxServices: 1 }),
    });

    const servicePreferencesDialogViewModel: ServicePreferencesDialogViewModel =
      {
        isOpen: true,
        data: modalData,
      };

    viewModel.dialogViewModel = servicePreferencesDialogViewModel;

    setProps({
      viewModel: {
        ...viewModel,
      },
    });

    enrichModalData({
      service,
      httpClient: flowAPI.httpClient,
      getServicesPreferencesModalData: () =>
        servicePreferencesDialogViewModel.data,
      setServicesPreferencesModalData: (data) => {
        servicePreferencesDialogViewModel.data = data;
        setProps({ viewModel: { ...viewModel } });
      },
      experiments: flowAPI.experiments,
      basket,
      constrains: modalData?.constrains,
    });
  };
};

const getBasket = (service: Service): Basket | undefined => {
  const { locations, staffMemberIds } = service;
  const location = locations?.[0];

  if (locations?.length! > 1 || isServiceVariedPricing(service)) {
    return;
  }

  const selectedStaff =
    staffMemberIds!.length > 1 ? DO_NOT_CARE_STAFF : staffMemberIds?.[0]!;

  return {
    location: {
      type: location?.type!,
      value:
        location?.business?.id ||
        location?.custom?.address?.formattedAddress ||
        '',
    },
    selectedServicesPreferences: [
      {
        service,
        resources: {
          staff: [selectedStaff],
        },
      },
    ],
  };
};
