import { BusinessInfo } from '@wix/bookings-uou-types/dist/src';
import { LocationMapper } from '../../mappers/location.mapper';
import { BookingPolicyDto } from '../../types/shared-types';
import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import { isCourseService } from '../../utils/service';
import {
  DurationMapperKeys,
  getDurationText,
  getPaymentDescription,
} from '@wix/bookings-calendar-catalog-viewer-mapper';

export interface DetailsSectionViewModel {
  duration?: string;
  durationAria?: string;
  price?: string;
  location?: string;
  locations?: string[];
  isBookable: boolean;
  ariaPrice?: string;
  numberOfSpotsLeft?: string;
  isFullyBooked?: boolean;
}

export enum CourseAvailabilityDisplay {
  NUMBER_OF_SPOTS = 'NUMBER_OF_SPOTS',
  AVAILABILITY = 'AVAILABILITY',
}

function getNumberOfSpots(spotsLeft: number, settings, t: Function) {
  let numberOfSpotsLeft = '';
  if (settings.displayNumberOfSpots) {
    const customAvailabilityMessageText: string =
      settings.numberOfSpotsLeftMessageText &&
      `${spotsLeft} ${settings.numberOfSpotsLeftMessageText}`;
    if (customAvailabilityMessageText) {
      numberOfSpotsLeft = customAvailabilityMessageText;
    } else if (spotsLeft > 1) {
      numberOfSpotsLeft = t('service.course.number-of-spots-left_plural', {
        count: spotsLeft,
      });
    } else {
      numberOfSpotsLeft = t('service.course.number-of-spots-left', {
        count: spotsLeft,
      });
    }
  } else {
    numberOfSpotsLeft = settings.availabilityMessageText;
  }
  return numberOfSpotsLeft;
}

export const detailsSectionViewModelFactory = ({
  t,
  businessInfo,
  service,
  isBookable,
  viewTimezone,
  bookingsPolicy,
  settings = {},
  experiments,
}: {
  t: Function;
  businessInfo?: BusinessInfo;
  service: Service;
  isBookable: boolean;
  viewTimezone?: string;
  bookingsPolicy: BookingPolicyDto;
  settings?: any;
  experiments?;
}): DetailsSectionViewModel => {
  const regionalSettingsLocale =
    businessInfo?.regionalSettingsLocale ?? 'en-US';
  const dateRegionalSettingsLocale =
    businessInfo?.dateRegionalSettingsLocale ?? 'en-US';

  const durationOptions: DurationMapperKeys = {
    hourUnit: 'duration.units.hours',
    hourAriaUnit: 'duration.units.aria-hours',
    minuteUnit: 'duration.units.minutes',
    minuteAriaUnit: 'duration.units.aria-minutes',
    durationVaries: 'duration.varies',
    durationRange: 'duration.range',
    coursePassedText: 'service.course.schedule.start-date-passed',
    courseInFutureText: 'service.course.schedule.start-date-in-future',
    courseEndedText: 'service.course.schedule.ended',
  };

  const isDynamicDurationUOUEnable = experiments.enabled(
    'specs.bookings.dynamicDurationUoU',
  );

  const duration = getDurationText({
    dateRegionalSettingsLocale,
    service,
    durationsMapperKeys: durationOptions,
    durationFormatter: t,
    viewTimezone,
    isDynamicDurationUOUEnable,
  });

  const durationAria = getDurationText({
    dateRegionalSettingsLocale,
    service,
    durationsMapperKeys: durationOptions,
    durationFormatter: t,
    viewTimezone,
    durationAria: true,
    isDynamicDurationUOUEnable,
  });

  const price = getPaymentDescription({
    payment: service.payment!,
    regionalSettingsLocale,
    paymentFormatters: {
      variedPrice: ({ minPrice, maxPrice }) =>
        service?.payment?.rateType === 'VARIED'
          ? t('service.payment.from-price', { price: minPrice, maxPrice })
          : price,
    },
  });

  const ariaPrice = getPaymentDescription({
    payment: service.payment!,
    regionalSettingsLocale,
    paymentFormatters: {
      variedPrice: ({ minPrice, maxPrice }) =>
        service?.payment?.rateType === 'VARIED'
          ? t('service.payment.from-price', { price: minPrice, maxPrice })
          : price,
    },
    currencyDisplay: 'name',
  });

  const clientLocationText = t('service.location.customer-place');
  const locationMapper = new LocationMapper(clientLocationText);

  const serviceDtoLocations = service.locations;
  const useBusinessName = !!serviceDtoLocations?.length;
  const locations = serviceDtoLocations
    ?.map((serviceLocation) =>
      locationMapper.text({
        serviceLocation,
        useBusinessName,
      }),
    )
    .filter((serviceLocation) => !!serviceLocation);

  const spots = bookingsPolicy.numberOfSpotsLeft!;
  const showAvailability =
    isCourseService(service) && spots > 0 && !bookingsPolicy.isTooLateToBook;
  const numberOfSpotsLeft =
    (showAvailability && getNumberOfSpots(spots, settings, t)) || '';
  return {
    duration,
    durationAria,
    price,
    locations,
    isBookable,
    ariaPrice,
    numberOfSpotsLeft,
    isFullyBooked: bookingsPolicy.isFullyBooked,
  };
};
