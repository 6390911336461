import { LocationMapper } from '../../mappers/location.mapper';
import {
  LocationType,
  Service,
} from '@wix/ambassador-bookings-services-v2-service/types';

export interface SchedulingLocationViewModel {
  currentLocation?: string;
  locationOptions?: LocationDropdownOption[];
}

export interface LocationDropdownOption {
  id: string;
  value: string;
}

export const All_LOCATIONS_ID = '';

export const schedulingLocationViewModelFactory = ({
  service,
  selectedLocation,
  t,
}: {
  service: Service;
  selectedLocation?: string;
  t: (key: string) => string;
}): SchedulingLocationViewModel => {
  let locationOptions: LocationDropdownOption[] = [];
  if (service.locations && service.locations.length > 1) {
    const locationMapper = new LocationMapper('');
    locationOptions = service.locations
      .filter(
        (serviceLocation) => serviceLocation.type === LocationType.BUSINESS,
      )
      .map((serviceLocation) => ({
        id: serviceLocation?.business?.id || '',
        value: locationMapper.text({
          serviceLocation,
          useBusinessName: true,
        }),
      }));
  }

  let currentLocation;
  if (selectedLocation) {
    currentLocation = selectedLocation;
  } else {
    currentLocation = '';
  }

  if (locationOptions.length < 2) {
    currentLocation = undefined;
  } else {
    locationOptions.splice(0, 0, {
      id: All_LOCATIONS_ID,
      value: t('app.scheduling.all-location.label'),
    });
  }

  return {
    currentLocation,
    locationOptions,
  };
};
